<template>
  <div class="customer">
    <div class="page-title">
      <span>客户信息</span>
      <el-button  type="primary" size="small" plain class="create"><i class="el-icon-circle-plus-outline"/> 新增客户</el-button>
    </div>



    <el-row type="flex" justify="space-around" align="middle">
      <el-col :span="16">
        <span class="customer-filter-name">客户类别</span>
        <el-radio-group
          size="small"
          v-model="pagination.type">
          <el-radio-button value="0">
            全部客户(500)
          </el-radio-button>
          <el-radio-button value="1">
            一般客户(35)
          </el-radio-button>
          <el-radio-button value="2">
            意向客户(65)
          </el-radio-button>
          <el-radio-button value="3">
            预成交客户(15)
          </el-radio-button>
          <el-radio-button value="4">
            成交客户(355)
          </el-radio-button>
          <el-radio-button value="5">
            停用客户(30)
          </el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col :span="8" class="customer-filter">
        <el-input-search placeholder="查找客户名称" class="customer-filter-search"></el-input-search>
        <el-dropdown class="customer-filter-orderby">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            按创建时间
            <el-icon type="down"/>
          </a>
          <el-menu slot="overlay">
            <el-menu-item>
              <a href="javascript:;">1st menu item</a>
            </el-menu-item>
            <el-menu-item>
              <a href="javascript:;">2nd menu item</a>
            </el-menu-item>
            <el-menu-item>
              <a href="javascript:;">3rd menu item</a>
            </el-menu-item>
          </el-menu>
        </el-dropdown>
        <my-icon type="iconjiangxu" class="customer-filter-icon"></my-icon>
      </el-col>
    </el-row>

    <div class="customer-list">
      <div class="customer-item" v-for="(customer, idx) in customers" :key="idx" @click="handleDetail(customer)">
            <div class="customer-item-title">
              <i class=" iconfont iconjinggao icon" v-if="customer.isAlarm"></i>
              <div class="name"><span class="label">客户名称:</span> <span class="value">{{customer.name}}</span></div>
              <div class="success">成交客户</div>
              <div>  <span class="label">负责人: 张三丰</span> </div>
              <div>  <span class="label">联络人: 张三丰</span>  </div>
              <div>  <span class="label">电话: 18500001234</span>  </div>
              <div class="tags">
<!--                <span class="label">客户标签:</span>-->
                <span class="label">创建日期:</span> {{new Date(customer.createdAt * 1000).Format('yyyy-MM-dd')}}
<!--                <el-tag size="small" v-for="(tag, _t) in customer.tags" :key="_t">{{tag.name}}</el-tag>-->
              </div>
            </div>

            <el-row type="flex" justify="space-around" align="middle">
              <el-col :span="5" class="item">
                <p class="item-value">备料充足</p>
                <p class="item-text margin-l">物料状况</p>
              </el-col>
              <el-col :span="5" class="item">
                <p class="item-value">{{1000 | I1000}}</p>
                <p  class="item-text">本月订单数</p>
              </el-col>
              <el-col :span="5" class="item">
                <p class="item-value">{{800.5 | F1000}}</p>
                <p  class="item-text">本月交易额<span class="unit">(元)</span></p>
              </el-col>
<!--              <el-col :span="3" class="item">-->
<!--                <p class="item-value">建议跟进</p>-->
<!--                <p  class="item-text margin-l">客户状况</p>-->
<!--              </el-col>-->
              <el-col :span="3" class="item">
                <p class="item-value">9 / 8</p>
                <p  class="item-text">设备总数/在线数</p>
              </el-col>
              <el-col :span="2" class="item next">
                <i class="el-icon-arrow-right"></i>
              </el-col>
            </el-row>
      </div>
    </div>

    <el-pagination class="customer-pagination" size="small" :default-current="3" :total="50" show-quick-jumper/>
  </div>
</template>

<script>
  export default {
    name: 'customer',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        pagination: {
          type: 0,
        },
        customers: [
          {
            uuid: 'test',
            isAlarm: true,
            name: '苏州智能科技有限公司',
            createdAt: 1605794986,
            tags:[
              {
                name: '大客户',
              },
              {
                name: '单台设备每周平均低于40杯预警',
              }
            ]
          },
        ]
      }
    },
    methods: {
      handleDetail(customer) {
        this.$router.push({name: 'salesman.customer.detail', params: {uuid: customer.uuid}})
      }
    },
  }
</script>

<style lang="scss" scoped>
  .customer {
    @include container();


    .create{
      float: right;
    }
    .create:after{
      @include clearfix();
    }

    &-title {
      margin-bottom: $middle-space;
    }

    &-tip {
      color: $theme-color;
      cursor: pointer;
      margin-left: $small-space;
    }

     .ant-radio-button-wrapper {
      font-size: $font-little-h;
    }

    &-filter {
      text-align: right;
      padding-right: $small-space;

      &-name {
        margin: 0 $small-space;
      }

      &-search {
        width: 200px;

      }

      &-orderby {
        margin: 0 $small-space;
      }

      &-icon {
        font-size: 18px;
      }
    }

    &-list {
      min-height: 560px;
      margin-top: $middle-space;
      .customer-item{
        min-height: 152px;
        border: 1px solid $content-border-color;
        border-radius: 4px;
        padding: $middle-space;
        cursor: pointer;
        margin-bottom: $middle-space;

        &-title{
          display: flex;
          justify-items: center;
          align-items: center;
          div{
            min-width: 120px;
            text-align: center;
          }
          .icon{
            color: $brand-warning;
            margin-right: $small-space;
          }
          .name{
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 264px;
          }
          .label{
            @include font-little-h();
            display: inline-block;
            margin-right: $small-space;
          }
          .value{
            color: $color-black;
          }

          .tags{
            flex: 1;
            text-align: right;
          }

          .success{
            color: $second-light-1;
          }
        }

        .next{
          text-align: center;
        }


        .item{
          margin-top: $middle-space;
          padding-left: $middle-space;
          text-align: center;
          &-text{
            .unit{
              @include font-little-h();
            }
          }

          .margin-l{
            margin-left: $small-space;
          }

          &-value{
            @include font-large-s();
            margin: $small-space 0;
          }
        }
      }
      .customer-item:hover{
        border-color: $theme-color;
        .next{
          color: $theme-color;
        }
      }
    }

    &-pagination {
      text-align: right;
      margin-bottom: $middle-space;
    }



  }
</style>
